var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入平台ID，如703" },
                    model: {
                      value: _vm.searchInfo.platformId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "platformId", $$v)
                      },
                      expression: "searchInfo.platformId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索标题" },
                    model: {
                      value: _vm.searchInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "title", $$v)
                      },
                      expression: "searchInfo.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "ID", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { label: "平台ID", prop: "platformId" }
          }),
          _c("el-table-column", { attrs: { label: "标题", prop: "title" } }),
          _c("el-table-column", {
            attrs: { label: "图片", prop: "cover" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: scope.row.cover,
                        "preview-src-list": [scope.row.cover]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "最大红包面值", prop: "maxRedPacket" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(" " + _vm._s(scope.row.maxRedPacket) + "元 ")]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "按钮颜色", prop: "buttonColor" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    !row.buttonColor
                      ? _c("span")
                      : row.buttonColor[1]
                      ? _c(
                          "el-button",
                          {
                            style: {
                              backgroundImage:
                                "linear-gradient(to bottom," +
                                row.buttonColor[0] +
                                "," +
                                row.buttonColor[1] +
                                ")",
                              border: 0
                            },
                            attrs: { type: "primary", round: "" }
                          },
                          [_vm._v("按钮")]
                        )
                      : _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              round: "",
                              color: row.buttonColor[0]
                            }
                          },
                          [_vm._v("按钮")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "背景色", prop: "backgroundColor" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      style:
                        "background:" +
                        scope.row.backgroundColor +
                        "; width:50px; height:50px"
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "分享banner", prop: "shareBanner" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: scope.row.shareBanner,
                        "preview-src-list": [scope.row.shareBanner]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "分享海报", prop: "sharePoster" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: scope.row.sharePoster,
                        "preview-src-list": [scope.row.sharePoster]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "分享文案", prop: "shareText" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updatePlatformInfo(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePlatformInfo(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑信息" : "创建信息",
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "platformInfoForm",
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "100px",
                rules: _vm.platformInfoRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台ID:", prop: "platformId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.platformId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "platformId", $$v)
                        },
                        expression: "formData.platformId"
                      }
                    },
                    _vm._l(_vm.platform_selections, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(
                              "【" + _vm._s(item.id) + "】" + _vm._s(item.name)
                            )
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "标题:", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.title,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "领取文案:",
                            prop: "buttonText.button1"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.formData.buttonText.button1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData.buttonText,
                                  "button1",
                                  $$v
                                )
                              },
                              expression: "formData.buttonText.button1"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "分享文案:",
                            prop: "buttonText.button2"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.formData.buttonText.button2,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData.buttonText,
                                  "button2",
                                  $$v
                                )
                              },
                              expression: "formData.buttonText.button2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片:", prop: "cover" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleAvatarSuccess
                      }
                    },
                    [
                      _vm.formData.cover
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.formData.cover }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注意事项:", prop: "notify" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.formData.notify,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "notify", $$v)
                      },
                      expression: "formData.notify"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "红包面值:", prop: "maxRedPacket" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入" },
                            model: {
                              value: _vm.formData.maxRedPacket,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "maxRedPacket",
                                  _vm._n($$v)
                                )
                              },
                              expression: "formData.maxRedPacket"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "按钮颜色:", prop: "buttonColor" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.formData.buttonColor[0],
                              callback: function($$v) {
                                _vm.$set(_vm.formData.buttonColor, 0, $$v)
                              },
                              expression: "formData.buttonColor[0]"
                            }
                          }),
                          _c("el-color-picker", {
                            model: {
                              value: _vm.formData.buttonColor[1],
                              callback: function($$v) {
                                _vm.$set(_vm.formData.buttonColor, 1, $$v)
                              },
                              expression: "formData.buttonColor[1]"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "背景色:", prop: "backgroundColor" } },
                [
                  _c("el-color-picker", {
                    model: {
                      value: _vm.formData.backgroundColor,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "backgroundColor", $$v)
                      },
                      expression: "formData.backgroundColor"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "流程图:" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "返利H5", "label-width": "20" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.imageAction,
                                "show-file-list": false,
                                "before-upload": _vm.imageBeforeUpload,
                                "on-success": _vm.handleFlH5AvatarSuccess
                              }
                            },
                            [
                              _vm.formData.processPicObj.fl_h5
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.formData.processPicObj.fl_h5
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "无返利H5", "label-width": "20" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.imageAction,
                                "show-file-list": false,
                                "before-upload": _vm.imageBeforeUpload,
                                "on-success": _vm.handleNFlH5AvatarSuccess
                              }
                            },
                            [
                              _vm.formData.processPicObj.nfl_h5
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.formData.processPicObj.nfl_h5
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "返利小程序", "label-width": "20" } },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.imageAction,
                                "show-file-list": false,
                                "before-upload": _vm.imageBeforeUpload,
                                "on-success": _vm.handleFlMpAvatarSuccess
                              }
                            },
                            [
                              _vm.formData.processPicObj.fl_mp
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.formData.processPicObj.fl_mp
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "无返利小程序", "label-width": "20" }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.imageAction,
                                "show-file-list": false,
                                "before-upload": _vm.imageBeforeUpload,
                                "on-success": _vm.handleNFlMpAvatarSuccess
                              }
                            },
                            [
                              _vm.formData.processPicObj.nfl_mp
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: _vm.formData.processPicObj.nfl_mp
                                    }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享图:", prop: "shareBanner" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleShareSuccess
                      }
                    },
                    [
                      _vm.shareBanner
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.shareBanner }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus vatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报底图:", prop: "sharePoster" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleHbSuccess
                      }
                    },
                    [
                      _vm.posterPic
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.posterPic }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台logo:", prop: "icon" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleIconSuccess
                      }
                    },
                    [
                      _vm.icon
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.icon }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享文案:", prop: "shareText" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入分享文案 支持占位符[TKL] [URL]",
                      rows: 5
                    },
                    model: {
                      value: _vm.formData.shareText,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "shareText", $$v)
                      },
                      expression: "formData.shareText"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享页注意事项:", prop: "shareNotify" } },
                [
                  _c("editor", {
                    model: {
                      value: _vm.formData.shareNotify,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "shareNotify", $$v)
                      },
                      expression: "formData.shareNotify"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享卡片文案:", prop: "shareCardTitle" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.shareCardTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "shareCardTitle", $$v)
                      },
                      expression: "formData.shareCardTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分享卡片图片:", prop: "shareCardPic" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleShareCardSuccess
                      }
                    },
                    [
                      _vm.shareCardPic
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.shareCardPic }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否发送客服消息",
                        prop: "xcxMessage.status"
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.formData.xcxMessage.status,
                          callback: function($$v) {
                            _vm.$set(_vm.formData.xcxMessage, "status", $$v)
                          },
                          expression: "formData.xcxMessage.status"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.formData.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息标题",
                            prop: "xcxMessage.title"
                          }
                        },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入客服消息标题",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.xcxMessage.title,
                              callback: function($$v) {
                                _vm.$set(_vm.formData.xcxMessage, "title", $$v)
                              },
                              expression: "formData.xcxMessage.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息图片",
                            prop: "xcxMessage.image"
                          }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                action: _vm.imageAction,
                                "show-file-list": false,
                                "before-upload": _vm.imageBeforeUpload,
                                "on-success": _vm.handleXcxSuccess
                              }
                            },
                            [
                              _vm.xcxUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.xcxUrl }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formData.xcxMessage.status
                ? _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "客服消息路径",
                            prop: "xcxMessage.path"
                          }
                        },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请选择客服消息路径",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.xcxMessage.path,
                              callback: function($$v) {
                                _vm.$set(_vm.formData.xcxMessage, "path", $$v)
                              },
                              expression: "formData.xcxMessage.path"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }